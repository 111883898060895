import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Flex, Box } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ShopifyImage } from '../types/shopify';
import { maxW } from '../assets/config';

type ImageFrameProps = {
  isSelected: boolean;
  children: React.ReactNode;
  handleIndex: () => void;
};
const ImageFrame = ({ isSelected, children, handleIndex }: ImageFrameProps) => {
  return (
    <Box
      border={isSelected ? '3px solid' : '1px solid'}
      borderColor={isSelected ? 'brand.pink' : 'gray'}
      onClick={handleIndex}
      w="12%"
    >
      {children}
    </Box>
  );
};

type SwipeableProductImagesProps = {
  images: ShopifyImage[];
};

const SwipeableProductImages = ({ images }: SwipeableProductImagesProps) => {
  const [index, setIndex] = React.useState(0);

  return (
    <>
      <SwipeableViews index={index} onChangeIndex={i => setIndex(i)}>
        {images.map(image => (
          <Box key={image.id} maxW="400px" mx="auto" textAlign="center">
            <GatsbyImage
              objectFit="cover"
              loading="eager"
              alt=""
              image={image.gatsbyImageData}
            />
          </Box>
        ))}
      </SwipeableViews>
      <Flex gridGap={2} pt={4} maxW="400px" mx="auto">
        {images.map((image, i) => (
          <ImageFrame
            key={i}
            isSelected={index === i}
            handleIndex={() => setIndex(i)}
          >
            <GatsbyImage
              objectFit="cover"
              loading="eager"
              alt=""
              image={image.gatsbyImageData}
            />
          </ImageFrame>
        ))}
      </Flex>
    </>
  );
};

export default SwipeableProductImages;
