import * as React from 'react';
import { navigate } from 'gatsby';
import { isEqual } from 'lodash';
import { AiOutlineClose } from 'react-icons/ai';

import SEO from '../components/seo';
import {
  Text,
  Flex,
  Accordion,
  AccordionItem,
  Box,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  IconButton,
  Heading,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { formatPrice } from '../utils/format-price';
import CartButton from '../components/CartButton';
import AddToCartButton from '../components/AddToCartButton';
import { maxW } from '../assets/config';
import SwipeableProductImages from '../features/SwipeableProductImages';
import JsonLd from '../components/JsonLd';
import { StoreContext } from '../context/StoreContext';
import { ShopifyProductVariant } from '../types/shopify';

type ProductPageProps = {
  pageContext: {
    node: {
      storefrontId: string;
      description: string;
      descriptionHtml: string;
      handle: string;
      images: {
        altText: string;
        gatsbyImageData: IGatsbyImageData;
        id: string;
      }[];
      priceRangeV2: {
        minVariantPrice: {
          amount: string;
          currencyCode: string;
        };
      };
      shopifyId: string;
      title: string;
      variants: ShopifyProductVariant[];
      options: {
        name: string;
        values: string;
        id: string;
      };
    };
  };
};

const ProductPage = ({ pageContext }: ProductPageProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { node } = pageContext;
  const { client } = React.useContext(StoreContext);

  const initialVariant = node.variants[0];

  const [variant, setVariant] = React.useState<ShopifyProductVariant>({
    ...initialVariant,
  });

  const productVariant =
    client.product.helpers.variantForOptions(node, variant) || variant;

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  );

  const checkAvailablity = React.useCallback(
    productId => {
      client.product
        .fetch(productId)
        .then((fetchedProduct: ShopifyBuy.Product) => {
          const result =
            fetchedProduct?.variants.filter(
              variant => variant.id === productVariant.storefrontId
            ) ?? [];

          if (result.length > 0) {
            setAvailable(result[0].available);
          }
        });
    },
    [productVariant.storefrontId, client.product]
  );

  const handleOptionChange = (index: number, selectedValue: string) => {
    const value = selectedValue;

    if (value === '') {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = node.variants.find(
      (variant: ShopifyProductVariant) => {
        return isEqual(currentOptions, variant.selectedOptions);
      }
    );

    if (selectedVariant) setVariant({ ...selectedVariant });
  };

  React.useEffect(() => {
    checkAvailablity(node.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, node.storefrontId]);

  const price = formatPrice(
    node?.priceRangeV2.minVariantPrice.currencyCode,
    Number(node?.priceRangeV2.minVariantPrice.amount)
  );

  const hasImages = node?.images.length > 0 || 0;
  const hasVariants = node?.variants.length > 1 || 0;

  return (
    <Layout fmb={40}>
      <SEO
        title={node?.title || '商品ページ'}
        description={node?.description || ''}
      />
      <JsonLd
        title={node?.title || '商品ページ'}
        description={node?.description || ''}
      />
      <Flex
        direction={isMobile ? 'column' : 'row'}
        w="100%"
        my={10}
        py={isMobile ? 10 : 4}
        maxW={maxW}
        mx="auto"
      >
        <Box w={isMobile ? '90%' : '50%'} mx="auto">
          {hasImages && <SwipeableProductImages images={node.images} />}
          {!hasImages && <span>商品画像がありません</span>}
        </Box>
        <Stack
          spacing={8}
          w={isMobile ? '90%' : '50%'}
          mt={8}
          maxW={maxW}
          mx="auto"
        >
          <Heading fontSize={{ base: '4xl', xl: '6xl' }} fontWeight="bold">
            {node?.title}
          </Heading>
          <Text fontSize="4xl" fontWeight={400}>
            {price}
          </Text>
          <Accordion allowToggle defaultIndex={0}>
            <AccordionItem>
              <Text as="h2" p={4} _focus={{ _focus: 'none' }}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontSize="xl" fontWeight={400}>
                      商品詳細
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel pb={4}>
                <Text
                  mt={4}
                  fontSize="xl"
                  dangerouslySetInnerHTML={{ __html: node?.descriptionHtml }}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box
            position="fixed"
            bottom={0}
            left={0}
            bg="white"
            w="full"
            zIndex="docked"
            borderTop="2px solid #999"
            boxShadow="md"
          >
            <Flex
              maxW={maxW}
              mx="auto"
              px={4}
              py={8}
              justify="center"
              alignItems="center"
              gap={4}
            >
              <IconButton
                aria-label="close"
                icon={<AiOutlineClose />}
                borderRadius="50%"
                size="lg"
                onClick={() => navigate(-1)}
              />
              <AddToCartButton
                available={available}
                variantId={productVariant.storefrontId}
                quantity={1}
              />
              <Box bg="purple.500" p={6} borderRadius="50%">
                <CartButton />
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Flex>
    </Layout>
  );
};

export default ProductPage;
