import * as React from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { AiOutlineShoppingCart } from 'react-icons/ai';

import { StoreContext } from '../context/StoreContext';

type Props = {
  available: boolean;
  variantId: string;
  quantity: number;
};

export default function AddToCartButton({
  available,
  variantId,
  quantity,
}: Props) {
  const toast = useToast();

  const { addVariantToCart, loading } = React.useContext(StoreContext);

  function addToCart(e: any) {
    e.preventDefault();
    addVariantToCart(variantId, quantity.toString());
    toast({
      title: '商品をカートに追加しました',
      status: 'success',
    });
  }

  return (
    <Button
      onClick={addToCart}
      leftIcon={<AiOutlineShoppingCart />}
      variant="solid"
      w="full"
      p={8}
      bg={!available ? 'gray.300' : 'purple.500'}
      color={!available ? 'black' : 'white'}
      fontSize="xl"
      disabled={!available || loading}
    >
      {!available ? '販売予定商品' : 'カートに入れる'}
    </Button>
  );
}
